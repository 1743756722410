var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cson-bg"},[_c('div',{staticClass:"codeDetail"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"codeContainer"},[_c('div',{staticClass:"codeQuestion"},[_vm._v(" "+_vm._s(_vm.codeInfo.questionNum)+"."+_vm._s(_vm.language == "en" ? _vm.codeInfo.name : _vm.codeInfo.chineseName)+" ")]),_c('div',{staticClass:"countNums"},[_c('span',{staticStyle:{"margin-right":"12px"}},[_c('span',{staticClass:"iconfont icon-liulan",staticStyle:{"margin-right":"4px"}}),_vm._v(_vm._s(_vm.codeInfo.pageViews))]),_c('span',{staticStyle:{"margin-right":"12px"}},[_c('span',{staticClass:"iconfont icon-dianzan",staticStyle:{"margin-right":"4px","cursor":"pointer"},on:{"click":function($event){return _vm.likeComments(_vm.codeInfo.id)}}}),_vm._v(_vm._s(_vm.codeInfo.likeNums))]),_c('span',{staticStyle:{"margin-right":"12px"}},[_c('span',{staticClass:"iconfont icon-huifu",staticStyle:{"margin-right":"6px"}}),_vm._v(_vm._s(_vm.codeInfo.commentNums))]),_c('span',{staticClass:"resolutionCounts"},[_vm._v(_vm._s(_vm.codeInfo.answers && _vm.codeInfo.answers.length)+"种解法")]),_c('span',{staticClass:"switchLanguage",on:{"click":_vm.switchLanguage}},[_c('svg',{staticClass:"css-1lc17o4-icon",attrs:{"viewBox":"0 0 24 24","width":"1em","height":"1em"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M12.87 15.07l-2.54-2.51.03-.03A17.52 17.52 0 0014.07 6H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z"}})]),_vm._v(" 切换到"+_vm._s(_vm.language == "en" ? "中文" : "英文"))])]),_c('div',{class:['richTextArea', _vm.language],domProps:{"innerHTML":_vm._s(
            _vm.language == 'en'
              ? _vm.codeInfo.leetcodeProblem
              : _vm.codeInfo.chineseLeetcodeProblem
          )}}),_vm._l((_vm.codeInfo.answers),function(answer,index){return _c('div',{key:index,staticClass:"answers"},[_c('div',{staticClass:"title"},[_vm._v("解法"+_vm._s(index + 1)+":")]),(answer.idea)?_c('div',{staticClass:"idea"},[_vm._v(_vm._s(answer.idea))]):_c('div',{staticClass:"idea"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":function($event){return _vm.preferIdea(answer.id)}}},[_vm._v("我来提供解法说明")]),_c('div',{staticStyle:{"margin-top":"12px"}},[_vm._v(" 提供解法说明，即可免费获得LeetCode视频讲解一天观看券（观看题数范围根据提供说明数决定） ")])],1),(
              answer.javaCode || answer.pythonCode || answer.cplusPlusCode
            )?_c('el-tabs',{attrs:{"type":"card"},model:{value:(answer.activeTab),callback:function ($$v) {_vm.$set(answer, "activeTab", $$v)},expression:"answer.activeTab"}},[(answer.javaCode)?_c('el-tab-pane',{attrs:{"label":"Java","name":"0"}},[_c('div',{directives:[{name:"highlight",rawName:"v-highlight"}],staticClass:"javaCode"},[_c('pre',[_vm._v("                  "),_c('code',{staticStyle:{"margin-top":"-15px"},domProps:{"textContent":_vm._s(answer.javaCode)}}),_vm._v("\n                ")])])]):_vm._e(),(answer.pythonCode)?_c('el-tab-pane',{attrs:{"label":"Python","name":"1"}},[_c('div',{directives:[{name:"highlight",rawName:"v-highlight"}],staticClass:"pythonCode"},[_c('pre',[_vm._v("                  "),_c('code',{staticStyle:{"margin-top":"-15px"},domProps:{"textContent":_vm._s(answer.pythonCode)}}),_vm._v("\n                ")])])]):_vm._e(),(answer.cplusPlusCode)?_c('el-tab-pane',{attrs:{"label":"C++","name":"2"}},[_c('div',{directives:[{name:"highlight",rawName:"v-highlight"}],staticClass:"cplusPlusCode"},[_c('pre',[_vm._v("                  "),_c('code',{staticStyle:{"margin-top":"-15px"},domProps:{"textContent":_vm._s(answer.cplusPlusCode)}}),_vm._v("\n                ")])])]):_vm._e()],1):_vm._e()],1)}),_c('div',{staticClass:"preferResolution"},[_c('el-button',{attrs:{"type":"success","round":""},on:{"click":_vm.preferResolution}},[_vm._v("提供新的解法")]),_c('div',{staticStyle:{"margin-top":"12px"}},[_vm._v(" 提供新的解法，即可免费获得LeetCode视频讲解一天观看券（观看题数范围根据提供说明数决定） ")])],1)],2),_c('Comments',{attrs:{"type":11,"comment-total":_vm.codeInfo.commentNums}}),(_vm.userInfo && _vm.userInfo.id)?_c('Reply',{attrs:{"type":11},on:{"reloadDetail":_vm.getCodeDetail}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }