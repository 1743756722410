<template>
  <div class="cson-bg">
    <div class="codeDetail">
      <div class="container">
        <div class="codeContainer">
          <div class="codeQuestion">
            {{ codeInfo.questionNum }}.{{
              language == "en" ? codeInfo.name : codeInfo.chineseName
            }}
          </div>
          <div class="countNums">
            <span style="margin-right: 12px"
              ><span
                class="iconfont icon-liulan"
                style="margin-right: 4px"
              ></span
              >{{ codeInfo.pageViews }}</span
            >
            <span style="margin-right: 12px"
              ><span
                class="iconfont icon-dianzan"
                style="margin-right: 4px; cursor: pointer"
                @click="likeComments(codeInfo.id)"
              ></span
              >{{ codeInfo.likeNums }}</span
            >
            <span style="margin-right: 12px"
              ><span
                class="iconfont icon-huifu"
                style="margin-right: 6px"
              ></span
              >{{ codeInfo.commentNums }}</span
            >
            <span class="resolutionCounts"
              >{{ codeInfo.answers && codeInfo.answers.length }}种解法</span
            >
            <span class="switchLanguage" @click="switchLanguage">
              <svg
                viewBox="0 0 24 24"
                width="1em"
                height="1em"
                class="css-1lc17o4-icon"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.87 15.07l-2.54-2.51.03-.03A17.52 17.52 0 0014.07 6H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z"
                ></path>
              </svg>

              切换到{{ language == "en" ? "中文" : "英文" }}</span
            >
          </div>
          <div
            :class="['richTextArea', language]"
            v-html="
              language == 'en'
                ? codeInfo.leetcodeProblem
                : codeInfo.chineseLeetcodeProblem
            "
          ></div>

          <div
            class="answers"
            v-for="(answer, index) in codeInfo.answers"
            :key="index"
          >
            <div class="title">解法{{ index + 1 }}:</div>

            <div class="idea" v-if="answer.idea">{{ answer.idea }}</div>
            <div class="idea" v-else>
              <el-button type="primary" round @click="preferIdea(answer.id)"
                >我来提供解法说明</el-button
              >
              <div style="margin-top: 12px">
                提供解法说明，即可免费获得LeetCode视频讲解一天观看券（观看题数范围根据提供说明数决定）
              </div>
            </div>
            <el-tabs
              v-model="answer.activeTab"
              type="card"
              v-if="
                answer.javaCode || answer.pythonCode || answer.cplusPlusCode
              "
            >
              <el-tab-pane label="Java" name="0" v-if="answer.javaCode">
                <div class="javaCode" v-highlight>
                  <pre>
                    <code style="margin-top:-15px;" v-text="answer.javaCode"></code>
                  </pre>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Python" name="1" v-if="answer.pythonCode">
                <div class="pythonCode" v-highlight>
                  <pre>
                    <code style="margin-top:-15px;" v-text="answer.pythonCode"></code>
                  </pre>
                </div>
              </el-tab-pane>
              <el-tab-pane label="C++" name="2" v-if="answer.cplusPlusCode">
                <div class="cplusPlusCode" v-highlight>
                  <pre>
                    <code style="margin-top:-15px;" v-text="answer.cplusPlusCode"></code>
                  </pre>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>

          <div class="preferResolution">
            <el-button type="success" round @click="preferResolution"
              >提供新的解法</el-button
            >
            <div style="margin-top: 12px">
              提供新的解法，即可免费获得LeetCode视频讲解一天观看券（观看题数范围根据提供说明数决定）
            </div>
          </div>
        </div>

        <Comments :type="11" :comment-total="codeInfo.commentNums"></Comments>
        <Reply
          v-if="userInfo && userInfo.id"
          :type="11"
          @reloadDetail="getCodeDetail"
        ></Reply>
      </div>
    </div>
  </div>
</template>
<script>
import { publishComment } from "@/service/comments";
import { getCodeDetail } from "@/service/leetcodeClassification";
import { mapState } from "vuex";
import Reply from "@/components/Reply";
import Comments from "@/components/Comments";
import { Toast } from "vant";
export default {
  name: "codeDetail",
  components: { Reply, Comments },
  data() {
    return {
      codeInfo: {},
      language: "en",
    };
  },
  mounted() {
    this.getCodeDetail();
    this.language = this.$route.query.language;
  },
  methods: {
    getCodeDetail() {
      getCodeDetail(this.$route.query.id).then((res) => {
        if (res.success) {
          this.codeInfo = res.result;
        }
      });
    },
    likeComments(commentId) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/wechatLogin");
        return;
      }
      let data;
      data = {
        type: false,
        parentId: 0,
        superId: commentId,
        superType: 11,
      };

      publishComment(data).then((res) => {
        if (res.success) {
          Toast("点赞成功！");
          this.getCodeDetail();
        } else {
          Toast("已点过赞");
        }
      });
    },

    preferResolution() {
      Toast("请电脑端访问cspiration.com进行填写");
    },
    preferIdea(answerId) {
      Toast("请电脑端访问cspiration.com进行填写");
    },
    switchLanguage() {
      this.$route.query.language = this.language == "en" ? "ch" : "en";
      this.language = this.language == "en" ? "ch" : "en";
    },
  },
  watch: {
    $route() {
      if (this.$route.query.language) {
        this.language = this.$route.query.language;
      }
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style lang="scss" scoped>
@import url("../../assets/leetcode/richtext.css");
.cson-bg {
  background-color: #f4f4f4;
}
.codeDetail {
  padding: 10px 0;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 54px;
  .container {
    width: 100%;
    .codeContainer {
      background: #fff;
      border-radius: 12px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 12px;
      padding: 30px;
      .codeQuestion {
        font-size: 18px;
        font-family: Poppins-Medium, Poppins;
        font-weight: 500;
        color: #34495e;
        line-height: 20px;
      }
      .countNums {
        margin-top: 20px;
      }
      .resolutionCounts {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #0a7aff;
        line-height: 18px;
        padding: 4px 8px;
        background: rgba(10, 122, 255, 0.1);
      }
    }
    .title {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #0075f6;
      line-height: 20px;
    }
  }
  .sideadvertisement {
    width: 300px;
  }
}
.commentArea {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 30px 48px;
  margin: 12px 0;
}
.userInfo.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.richTextArea {
  margin-top: 30px;
}
.answers {
  padding: 20px 0;
  .title {
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #0075f6;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .idea {
    text-align: center;
    padding: 16px;
    background: rgba(0, 117, 246, 0.1);
    border-radius: 12px;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #0075f6;
    line-height: 20px;
    margin-bottom: 20px;
    .el-button {
      width: 100%;
    }
  }
}
.preferResolution {
  text-align: center;

  background: rgba(45, 166, 65, 0.1);
  border-radius: 12px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #2da641;
  line-height: 20px;
  padding: 16px;
  .el-button {
    width: 100%;
  }
}
.switchLanguage {
  margin-left: 12px;
  cursor: pointer;
  line-height: 24px;
}
::v-deep .w-e-content-container {
  overflow: scroll;
}
</style>